import { Container } from 'react-bootstrap';

interface AppFrameProps {
    children: React.ReactNode;
    banner?: React.ReactNode;
}

const AppFrame: React.FC<AppFrameProps> = ({ children, banner }) => {
    return (
        <div className='page-container'>
            {banner? banner : null}
            <div className="d-flex justify-content-center">
                <Container className="m-3" style={{maxWidth: '1200px' }}>
                    {children}
                </Container>
            </div>
        </div>
        
    );
}

export default AppFrame;