import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { DropdownItem } from '../models/dropdownModel';

interface NavbarLinkProps {
    label: string;
    path: string;
    dropdown?: DropdownItem[];
    page?: React.ReactNode;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ label, path, dropdown }) => {
    return (
        <>
            {dropdown ? (
                <NavDropdown title={label} id={`${label}-dropdown`}>
                    {dropdown.map((item, index) => (
                        item.divider ? (
                            <NavDropdown.Divider key={index} />
                        ) : (
                            <NavDropdown.Item key={index} href={item.path}>
                                {item.label}
                            </NavDropdown.Item>
                        )
                    ))}
                </NavDropdown>
            ) : (
                <Nav.Link href={path}>{label}</Nav.Link>
            )}
        </>
    );
};

export default NavbarLink;