import AppFrame from './AppFrame';
import ProcessForm from '../components/inputComponents/process-form';

const RaportGenereator: React.FC = () => {
    return (
        <>
           <AppFrame children={<ProcessForm bg='var(--70color)' className='p-5 mt-3' />} /> 
        </>
    );
}

export default RaportGenereator;