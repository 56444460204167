import { formatNumberOneDecimal } from "./tidsFormatering";

/**
 * Calculates the Return on Investment (ROI) in percentage for a given period.
 *
 * @param {number} numberOfMonths - The number of months over which to calculate the ROI.
 * @param {number} monthlySavings - The savings per month.
 * @param {number} oneTimeCosts - The one-time costs.
 * @param {number} monthlyCosts - The recurring monthly costs.
 * @returns {number} The ROI in percentage.
 */
const calculateROI = (
    numberOfMonths: number,
    monthlySavings: number,
    oneTimeCosts: number,
    monthlyCosts: number
): number => {
    const totalSavings = monthlySavings * numberOfMonths;
    const totalCosts = oneTimeCosts + (monthlyCosts * numberOfMonths);
    const roi = ((totalSavings - totalCosts) / totalCosts) * 100;
    const formattedROI: number = parseFloat(formatNumberOneDecimal(roi) as string);
    return formattedROI;
};

export default calculateROI;