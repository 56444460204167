/**
 * ProcessForm component renders a form to gather information about a process,
 * including process name, time spent, number of employees, average salary, 
 * and potential automation savings. It generates a report based on the input values.
 *
 * @component
 * @example
 * return (
 *   <ProcessForm bg="white" className="my-custom-class" />
 * )
 *
 * @param {Object} props - Component properties.
 * @param {string} [props.bg="transparent"] - Background color of the container.
 * @param {string} [props.className] - Additional class names for the container.
 *
 * @returns {JSX.Element} The rendered ProcessForm component.
 */
import React, {useState} from "react";
import Input from "../input";
import RapportMedarbFrekLoen from "../rapportViserComponenter/rapportMedarbFrekLoen";
import {Button, Container, Form} from "react-bootstrap";
import { ProcessFormValues } from "../../models/processFormModel";
import { ProcessTid } from "../../models/processFormModel";
import generateProcessRapportFromMedarbFrekvensLoen from "../../services/rapportGenerator";
import { ProcessRapport } from "../../models/rapportModel";
import RapportModal from "../rapportModal";

interface ProcessFormProps {
    bg?: string;
    className?: string;
}

const ProcessForm: React.FC<ProcessFormProps> = ({bg="transparent", className}) => {
    const [inputValues, setInputValues] = useState<ProcessFormValues>({
        procesNavn: '',
        procesTid: { 
            procesTidISekunder: 1,
            om: "",
            frekvens: "sekunder"
        },
        antalMedarbejdere: 0,
        gennemsnitligLøn: 0,
        reduktionTidProcent: 0,
        tilbudGivet: {
            tilbud: false,
            tilbudPris: 0,
            løbendeUdgifterPrMdr: 0,
            løbendeUdgifterPrÅr: 0
        }
    });
    
    // Modal state
    const [showModal, setShowModal] = useState(false);
    // Modal open/close functions
    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [rapport, setRapport] = useState<ProcessRapport | null>(null);
    
    const [showAdditionalInputs, setShowAdditionalInputs] = useState<boolean>(false);
    const [validationMessages, setValidationMessages] = useState<{[key: string]: string}>({});
    const [validationMessageOn, setValidationMessageOn] = useState<boolean>(false);

    const handleValueChange = (key: string, newValue: string | number | ProcessTid | boolean) => {
        setInputValues(prevValues => {
            if (typeof newValue === 'object' && newValue !== null) {
                if (JSON.stringify(prevValues.procesTid) === JSON.stringify(newValue)) {
                    return prevValues;
                }
                return {
                    ...prevValues,
                    procesTid: newValue
                };
            } else if (key.startsWith('tilbudGivet.')) {
                const subKey = key.split('.')[1] as keyof typeof prevValues.tilbudGivet;
                if (prevValues.tilbudGivet[subKey] === newValue) {
                    return prevValues;
                }
                return {
                    ...prevValues,
                    tilbudGivet: {
                        ...prevValues.tilbudGivet,
                        [subKey]: newValue
                    }
                };
            } else {
                if (prevValues[key as keyof ProcessFormValues] === newValue) {
                    return prevValues;
                }
                return {
                    ...prevValues,
                    [key]: newValue,
                };
            }
        });
    };

    // Validate input values
    const validateInputs = () => {
        const messages: {[key: string]: string} = {};
        let isValid = true;

        if (inputValues.antalMedarbejdere <= 0) {
            messages.antalMedarbejdere = 'Antal medarbejdere skal være større end 0.';
            isValid = false;
        }
        if (inputValues.gennemsnitligLøn <= 0) {
            messages.gennemsnitligLøn = 'Gennemsnitlig løn skal være større end 0.';
            isValid = false;
        }
        if (inputValues.reduktionTidProcent <= 0) {
            messages.reduktionTidProcent = 'Reduktion i tid procent skal være større end 0.';
            isValid = false;
        }
        if (inputValues.procesTid.procesTidISekunder <= 0) {
            messages.procesTid = 'Procestid skal være større end 0.';
            isValid = false;
        }

        setValidationMessageOn(!isValid);
        setValidationMessages(messages);
        return isValid;
    };
    
    return (
        <>
        <Container className={`rounded ${className}`} style={{backgroundColor: `${bg}`}} >
            <h1 className=""><b>Rapportgenerator</b></h1>
            <p>
                <em>Få et hurtigt overblik over, hvor meget din organisation kan vinde ved at automatisere en proces.</em>
            </p>
            <p>
                Du skal bruge disse informationer for at generere en rapport:
                
            </p>
            <ol>
                <li>Frekvens og tid brugt på processen</li>
                <li>Antal medarbejdere</li>
                <li>Gennemsnitlig personaleomkostninger (pr. time)</li>
                <li>Målsætning for reduktion i manuel arbejdstid</li>
                <li><em>(Valgfrit)</em> Tilbudspris på en løsning og løbende udgifter pr. år</li>
            </ol>
            <Form>
                <div>
                    <Input 
                        inputType="text"
                        inputLabel="Proces-navn:"
                        placeholder='Indtast procesnavn - fx. "Kontraktbehandling"'
                        onValueChange={(newValue) => handleValueChange('procesNavn', newValue)}
                        inputWidth="25"
                    />
                </div>
                <div>
                    <Input
                        inputLabel='Frekvens'
                        inputType="frekvens"
                        textBefore='Der bruges pr. medarbejder'
                        firstOptions={["dagligt (kun hverdage)", "dagligt (også weekender)", 'ugentligt', 'månedligt']}
                        textMid="gennemsnitligt"
                        secondOptions={['sekunder', 'minutter', 'timer']}
                        textAfter='på processen.'
                        placeholderNumber={10}
                        onValueChange={(newValue) => handleValueChange("procesTid", newValue)}
                    />
                </div>
                <div>
                    <Input
                        inputLabel='Hvor mange medarbejdere arbejder på processen?'
                        inputType="number"
                        inputWidth="4"
                        placeholderNumber={5}
                        onValueChange={(newValue) => handleValueChange("antalMedarbejdere", newValue)}
                        validationMessage={validationMessages.antalMedarbejdere}
                    />
                </div>
                <div>
                    <Input
                        inputLabel='Hvad er medarbejderens/medarbejdernes gennemsnitlige bruto-timeløn? (Bruto = nettoløn, pension, feriepenge, lønsumsafgift, etc.)'
                        inputType="number"
                        inputWidth="8"
                        placeholderNumber={250}
                        onValueChange={(newValue) => handleValueChange("gennemsnitligLøn", newValue)}
                        validationMessage={validationMessages.gennemsnitligLøn}
                    />
                </div>
                <div>
                    <Input 
                        inputLabel="Angiv målsætning for reduction i tid (Hvis der ønskes automatisering angiv 100%):"
                        inputType="procent"
                        pMin={0}
                        pMax={100}
                        onValueChange={(newValue) => handleValueChange('reduktionTidProcent', newValue)}
                        validationMessage={validationMessages.reduktionTidProcent}
                    />
                </div>
                <div>
                    <Form.Check 
                        type="checkbox" 
                        label="Ønsker du at angive tilbudspris og løbende udgifter pr. år?" 
                        onChange={(e) => {
                            setShowAdditionalInputs(e.target.checked);
                            handleValueChange('tilbudGivet.tilbud', e.target.checked);
                        }}
                    />
                </div>
                {showAdditionalInputs && (
                    <>
                        <div>
                            <Input
                                inputLabel='Tilbudspris:'
                                inputType="number"
                                inputWidth="8"
                                placeholderNumber={1000}
                                onValueChange={(newValue) => handleValueChange("tilbudGivet.tilbudPris", newValue)}
                            />
                        </div>
                        <div>
                            <Input
                                inputLabel='Løbende udgifter pr. år:'
                                inputType="number"
                                inputWidth="8"
                                placeholderNumber={1000}
                                onValueChange={(newValue) => handleValueChange("tilbudGivet.løbendeUdgifterPrÅr", newValue)}
                            />
                        </div>
                    </>
                )}
            </Form>
            {validationMessageOn ? <><br/><p className="text-danger mb-0">Udfyld venligst alle felter korrekt.</p></> : null}
            <Button onClick={() => {
                if (validateInputs()) {
                    const generatedRapport = generateProcessRapportFromMedarbFrekvensLoen(inputValues);
                    setRapport(generatedRapport);
                    handleOpenModal();
                    setValidationMessageOn(false);
                }
            }}>Generer Rapport</Button>
        </Container>
        {rapport &&  
        <>
        <RapportModal children={<RapportMedarbFrekLoen rapport={rapport}/>} handleClose={handleCloseModal} show={showModal} title="Process-rapport" key={"rapportModal"} toolTip="Rapporten giver virksomhedsejere og beslutningstagere et hurtigt overblik over, om det kan betale sig at automatisere en proces. Den er især nyttig, når målet er at reducere personaleindsats eller effektivisere delprocesser, som i dag udføres manuelt."/>
        </> 
        }
        </>
    )
};

export default ProcessForm;
