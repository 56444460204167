/**
 * ImageSection component renders a section with an image and accompanying text.
 * It allows for a customizable layout with optional reverse layout and background color.
 *
 * @component
 * @example
 * // Example usage:
 * <ImageSection
 *   title="Sample Title"
 *   text="This is a sample text accompanying the image."
 *   imageLink="https://sampleimagelink.com"
 *   reverseLayout={true}
 *   primText="Primary Button"
 *   firstPath="/primary-path"
 *   secText="Secondary Button"
 *   secondPath="/secondary-path"
 *   className="custom-class"
 *   bg="#f0f0f0"
 * />
 *
 * @param {Object} props - The properties object.
 * @param {string} props.title - The title text to display.
 * @param {string} props.text - The descriptive text to display.
 * @param {string} props.imageLink - The URL link to the image.
 * @param {boolean} [props.reverseLayout=false] - If true, the layout will be reversed.
 * @param {string} props.primText - The text for the primary button.
 * @param {string} props.firstPath - The URL path for the primary button action.
 * @param {string} props.secText - The text for the secondary button.
 * @param {string} props.secondPath - The URL path for the secondary button action.
 * @param {string} [props.className] - Additional class names for custom styling.
 * @param {string} [props.bg='transparent'] - Background color for the container.
 * @param {number} [props.maxParagraphsOnImageSide=2] - Maximum number of paragraphs to display on the image side (default is 2).
 *
 * @returns {JSX.Element} The rendered ImageSection component.
 */
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

interface ImageSectionProps {
    title: string;
    text: string | React.ReactNode;
    imageLink: string;
    reverseLayout?: boolean;
    primText?: string;
    firstPath?: string;
    secText?: string;
    secondPath?: string;
    className?: string;
    bg?: string;
    maxParagraphsOnImageSide?: number;
}

const ImageSection: React.FC<ImageSectionProps> = ({ title, text, maxParagraphsOnImageSide=2, imageLink, reverseLayout=false, primText, firstPath, secText, secondPath, className, bg='transparent' }) => {
    if ((primText && !firstPath) || (!primText && firstPath)) {
        console.error("If 'primText' is provided, 'firstPath' must also be provided, and vice versa.");
    }

    if ((secText && !secondPath) || (!secText && secondPath)) {
        console.error("If 'secText' is provided, 'secondPath' must also be provided, and vice versa.");
    }

    // Function to handle splitting React elements into children
    const renderText = (text: string | React.ReactNode): React.ReactNode => {
        if (typeof text === "string") {
            // Convert string to an array of children
            const textArray = text.split("\n"); // Or use a different delimiter if necessary
            return textArray.map((line, index) => <p key={index}>{line}</p>);
        } else if (React.isValidElement(text)) {
            // If it is a React element, split its children if possible
            const children = React.Children.toArray(text.props.children);
            return children.map((child, index) => <React.Fragment key={index}>{child}</React.Fragment>);
        }
        return text; // If it's already a React node
    };

    // Function to sort children into categories
    const sortChildrenByParagraph = (children: React.ReactNode[], maxParagraphs: number = maxParagraphsOnImageSide): {
        beforeFirstParagraph: React.ReactNode[];
        chosenParagraphs: React.ReactNode[];
        afterFirstParagraph: React.ReactNode[];
    } => {
        const beforeFirstParagraph: React.ReactNode[] = [];
        const chosenParagraphs: React.ReactNode[] = [];
        const afterFirstParagraph: React.ReactNode[] = [];

        let paragraphCount = 0;
        let foundParagraphs = false;

        for (const child of children) {
            if (!foundParagraphs && React.isValidElement(child) && React.Children.toArray(child.props.children).some(grandChild => React.isValidElement(grandChild) && grandChild.type === 'p')) {
                if (paragraphCount < maxParagraphs) {
                    chosenParagraphs.push(child);
                    paragraphCount++;
                } else {
                    foundParagraphs = true;
                    afterFirstParagraph.push(child);
                }
            } else if (foundParagraphs) {
                afterFirstParagraph.push(child);
            } else {
                beforeFirstParagraph.push(child);
            }
        }

        return { beforeFirstParagraph, chosenParagraphs, afterFirstParagraph };
    };

    const ReactElements = renderText(text);

    let sortedChildren = null;
    if (Array.isArray(ReactElements)) {
        sortedChildren = sortChildrenByParagraph(ReactElements, maxParagraphsOnImageSide);
    }



    const imageSection = (
        <Col style={{ padding: '2.5%', maxWidth: '100%', height: 'auto', minWidth: '33%' }} xs={{order: 2}} md={{order: 1}}>
            <img src={imageLink} alt={title} style={{ width: '100%', height: 'auto' }} className="rounded"/>
        </Col>
    );

    const textSectionText = (
        <Col  className={reverseLayout ? `ps-1 ` : `pe-1 `} style={{ padding: '2.5%', maxWidth: '100%' }} xs={{order: 1}} md={{order: 2}}>
            <h2>{title}</h2>
            <p>{text}</p>
        </Col>
    );

    const textSectionObject = (
        <>
                <Col className={reverseLayout ? `ps-1 ` : `pe-1 `} style={{ padding: '2.5%', maxWidth: '100%' }}>
                    <h2>{title}</h2>
                    {sortedChildren ? sortedChildren.beforeFirstParagraph : null}
                    {sortedChildren ? sortedChildren.chosenParagraphs : null}
                </Col>
                <Col xs={9} sm={6} md={5} lg={6} xl={4} xxl={4}  className="px-4">
                    <img src={imageLink} alt={title} style={{ width: '90%', height: 'auto', }} className="rounded"/>
                </Col>
            <Row >
                <Col style={{ paddingLeft: '2.5%', paddingRight: '2.5%', maxWidth: '100%' }}>
                    {sortedChildren ? sortedChildren.afterFirstParagraph : null}
                </Col>
            </Row>
        </>
    );

    return (
        <div className="">
            <Container className={`rounded ${className}`} style={{ padding: '1em', backgroundColor: `${bg}` }}>
                <Row className={`align-items-center ${reverseLayout ? 'flex-row-reverse' : 'flex-row'}`}>
                    {sortedChildren && sortedChildren.chosenParagraphs.length !== 0 ? null : imageSection}
                    {sortedChildren && sortedChildren.chosenParagraphs.length !== 0 ? textSectionObject : textSectionText}
                </Row>
            </Container>
        </div>
    );
};

export default ImageSection;