import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const FooterMain: React.FC = () => {
    return (
            <footer className="footer bg-color20 text-white mt-5 p-4 text-center">
                <Container>
                    <Row>
                        
                        <Col md="8">
                            <Row>
                                <Col md="3">
                                    <h3>Weng Consult</h3>
                                </Col>
                                <Col md="9">
                                    <p>Weng Consult er dedikeret til at lave redskaber til virksomhedsejere og processanvarlige, for at gøre det nemt og overskueligt at vurderer om en automatisering er værd at implimenterer.</p>
                                    {/* <p>Weng Consult er et konsulenthus, der hjælper virksomheder med at optimere deres processer og udvikle deres medarbejdere.</p> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col md="4">
                            <Row>
                                <Col className='text-end' md="3">CRV:</Col>
                                <Col className='text-start' md="9">42984981</Col>
                            </Row>
                            <Row>
                                <Col className='text-end' md="3">Tlf:</Col>
                                <Col className='text-start' md="9">+45 27 85 20 90</Col>
                            </Row>
                            <Row>
                                <Col className='text-end' md="3">Adresse:</Col>
                                <Col className='text-start' md="9">Ørvigvej 22<br/> 6040 Egtved</Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pt-3' md="12">
                            <p>&copy; {new Date().getFullYear()} Weng Consult. All rights reserved.</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
    );
};

export default FooterMain;

