import React, { ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import QuestionIconWithTooltip from './questionCirkelTooltip';

type RapportModalProps = {
  show: boolean;
  handleClose: () => void;
  children: ReactNode;
  title: string;
  footerContent?: ReactNode;
  toolTip?: string;
};

/**
 * RapportModal component - A reusable modal component for displaying custom content.
 * 
 * Props:
 * - show: boolean - Controls whether the modal is visible.
 * - handleClose: () => void - Callback function to handle closing the modal.
 * - title: string - Title text displayed at the top of the modal.
 * - children: ReactNode - Content to be displayed inside the modal body.
 * - footerContent?: ReactNode - Optional content to be displayed in the modal footer. If not provided, a default close button is used.
 * - toolTip?: string - Optional tooltip text to be displayed next to the title.
*/
const RapportModal: React.FC<RapportModalProps> = ({ show, handleClose, children, title, footerContent, toolTip }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" dialogClassName="modal-90w">
      <Modal.Header closeButton className='bg-color20 txt-color20'>
        <Modal.Title>{title} {(toolTip ? <QuestionIconWithTooltip text={toolTip}/> : null)} </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-color70'>
        {children}
      </Modal.Body>
      <Modal.Footer className='bg-color20 txt-color20'>
        {footerContent ? footerContent : (
          <Button variant="secondary" onClick={handleClose}>
            Luk
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RapportModal;
