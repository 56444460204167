import React from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

/**
 * Props for the Hero component.
 * Hero is a component that displays a hero section with an image, heading, text, and two buttons.
 * It is positioned in the center of the screen and can be reversed to display the image on the right side.
 * 
 * @interface HeroProps
 * 
 * @property {string} overskrift - The main heading text for the hero section.
 * @property {string} heroText - The descriptive text for the hero section.
 * @property {string} heroIMG - The URL of the image to be displayed in the hero section.
 * @property {boolean} [reverseLayout] - Optional. If true, the layout of the hero section will be reversed.
 * @property {boolean} [mirrorImage] - Optional. If true, the image will be mirrored.
 * @property {string} firstPath - The URL path for the first button.
 * @property {string} secondPath - The URL path for the second button.
 * @property {string} [firstButtonText] - Optional. The text for the first button.
 * @property {string} [secondButtonText] - Optional. The text for the second button.
 * @property {string} [bg] - Optional. The background color or image for the hero section.
 * @property {string} [className] - Optional. Additional CSS classes to apply to the hero section.
 */
interface HeroProps {
    overskrift: string;
    heroText: string;
    heroIMG: string;
    reverseLayout?: boolean;
    mirrorImage?: boolean;
    firstPath: string;
    secondPath: string;
    firstButtonText?: string;
    secondButtonText?: string;
    bg?: string;
    className?: string;
}

const Hero: React.FC<HeroProps> = ({ overskrift, heroText, heroIMG, reverseLayout = false, mirrorImage = false, firstPath, secondPath, firstButtonText = "Kontakt", secondButtonText = "Læs mere", bg="transparent", className }) => {
    const navigate = useNavigate();

    const handleClickPrimary = () => {
        navigate(firstPath);
    };

    const handleClickSecondary = () => {
        navigate(secondPath);
    };

    const imageSection = (
        <img
            id="heroStartImg"
            src={heroIMG}
            style={{ transform: mirrorImage ? 'scaleX(-1)' : 'none' }}
            className="d-block mx-lg-auto img-fluid"
            alt="Jonas Weng"
            loading="lazy"
        />
    );

    const textSection = (
        <Container className={reverseLayout ? `ms-4 ` : `me-4 `}>
            <Row>
                <h1
                    id="heroMainText"
                    className="display-5 fw-bold lh-1 mb-3"
                >
                    {overskrift}
                </h1>
            </Row>
            <Row className="lead px-3">
                {heroText}
            </Row>
            <Row className="pt-4">
                <Col className="d-flex justify-content-start">
                    <Button onClick={handleClickPrimary} variant="primary" size="lg" className="px-4 læs-mere">
                        {firstButtonText}
                    </Button>
                    <Button onClick={handleClickSecondary} variant="outline-secondary" size="lg" className="px-4 me-md-2 ms-4">
                        {secondButtonText}
                    </Button>
                </Col>
            </Row>
            
        </Container>
    );

    return (
        <Container className={`rounded ${className}`} style={{backgroundColor: `${bg}`}}>
            <Row className={`py-4`}>
            {reverseLayout ? (
                <>
                <Col lg={6} sm={12} className="d-flex align-items-center">
                    {textSection}
                </Col>
                <Col xs={12} sm={10} lg={6} className="d-flex align-items-center">
                    {imageSection}
                </Col>
                </>
            ) : (
                <>
                <Col xs={12} sm={10} lg={6} className="d-flex align-items-center">
                    {imageSection}
                </Col>
                <Col lg={6} sm={12} className="d-flex align-items-center">
                    {textSection}
                </Col>
                </>
            )}
            </Row>
        </Container>
    );
};

export default Hero;