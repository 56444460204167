import React, { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { DropdownItem } from '../models/dropdownModel';

interface RouteMapProps {
    basicLayout: ReactNode;
    links: { 
        label: string; 
        path: string; 
        dropdown?: DropdownItem[]; 
        page?: ReactNode; 
    }[];
}

/**
 * `RouteMap` component is responsible for rendering a set of routes based on the provided configuration.
 * It uses `react-router-dom` to define the routes and their corresponding components.
 *
 * @component
 * @param {RouteMapProps} props - The properties for the RouteMap component.
 * @param {ReactNode} props.basicLayout - The basic layout component to be rendered at the root path.
 * @param {Array<{ label: string, path: string, dropdown?: DropdownItem[], page?: ReactNode}>} props.links - An array of link objects defining the routes and their corresponding components.
 *
 * @example
 * const basicLayout = <BasicLayout />;
 * const links = [
 *   { label: 'Home', path: 'home', page: <HomePage /> },
 *   { label: 'About', path: 'about', page: <AboutPage /> },
 *   { 
 *     label: 'Services', 
 *     path: 'services', 
 *     dropdown: [
 *       { label: 'Consulting', path: 'consulting', page: <ConsultingPage /> },
 *       { label: 'Development', path: 'development', page: <DevelopmentPage /> },
 *       { divider: true }, // Divider
 *       { label: 'Support', path: 'support', page: <SupportPage /> }
 *     ]
 *   }
 * ];
 */

const RouteMap: React.FC<RouteMapProps> = ({ basicLayout, links }) => {
    return (
        <Routes>
            <Route path="/" element={basicLayout}>
                {links.map((link, index) => (
                    <React.Fragment key={index}>
                        {link.page && <Route path={link.path} element={link.page} />}
                        {link.dropdown && link.dropdown.map((dropdownItem, dropdownIndex) => (
                            !dropdownItem.divider && (
                                <Route key={dropdownIndex} path={dropdownItem.path} element={dropdownItem.page} />
                            )
                        ))}
                    </React.Fragment>
                ))}
            </Route>
        </Routes>
    );
};

export default RouteMap;