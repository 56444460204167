import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { ProcessTid } from '../models/processFormModel';
import { FrekvensOptions } from '../models/frekvensOptionsModel';


type InputType = 'text' | 'frekvens' | 'select' | 'radio' | 'time' | 'number' | 'procent' | 'checkbox';

type CommonInputProps = {
    inputLabel: string;
    inputText?: string;
    placeholder?: string;
    options?: string[];
    frekvens?: string;
    inputWidth?: string;
    onValueChange: (newValue: string | number | ProcessTid) => void;
    validationMessage?: string | undefined;
};

type FrekvensInputProps = CommonInputProps & {
    inputType: 'frekvens';
    firstOptions: FrekvensOptions[];
    secondOptions: FrekvensOptions[];
    /** Text that comes before the first input */
    textBefore?: string;
    /** Text that comes between the first and second input */
    textMid?: string;
    /** Text that comes after the second input */
    textAfter?: string;
    /** Widt is measured in "em". */
    placeholderNumber?: number;
};

type ProcentInputProps = CommonInputProps & {
    inputType: 'procent';
    pMin: number;
    pMax: number;
};

type NumberInputProps = CommonInputProps & {
    inputType: 'number';
    placeholderNumber: number;
};

type OtherInputProps = CommonInputProps & {
    inputType: Exclude<InputType, 'frekvens' | 'procent' | "number">;
};


type InputProps = FrekvensInputProps | OtherInputProps | ProcentInputProps | NumberInputProps;


/**
 * A versatile Input component that supports various input types including text, frekvens, select, radio, time, number, procent, and checkbox.
 * 
 * @component
 * @param {InputProps} props - The properties for the Input component.
 * @param {string} props.inputLabel - The label for the input field.
 * @param {string} [props.inputText] - Additional text to display below the input field.
 * @param {string} [props.placeholder] - Placeholder text for the input field.
 * @param {string[]} [props.options] - Options for select and radio input types.
 * @param {string} [props.frekvens] - Frequency value for frekvens input type.
 * @param {string} [props.inputWidth] - Width of the input field in em units.
 * @param {(newValue: string | number | ProcessTid) => void} props.onValueChange - Callback function to handle value changes.
 * @param {InputType} props.inputType - The type of input field.
 * @param {FrekvensOptions[]} [props.firstOptions] - First set of options for frekvens input type.
 * @param {FrekvensOptions[]} [props.secondOptions] - Second set of options for frekvens input type.
 * @param {string} [props.textBefore] - Text that comes before the first input in frekvens type.
 * @param {string} [props.textMid] - Text that comes between the first and second input in frekvens type.
 * @param {string} [props.textAfter] - Text that comes after the second input in frekvens type.
 * @param {number} [props.placeholderNumber] - Placeholder number for number and procent input types.
 * @param {number} [props.pMin] - Minimum value for procent input type.
 * @param {number} [props.pMax] - Maximum value for procent input type.
 * 
 * @example
 * <Input 
 *   inputLabel="Enter your name"
 *   inputType="text"
 *   placeholder="Name"
 *   onValueChange={(value) => console.log(value)}
 * />
 * 
 * @example
 * <Input 
 *   inputLabel="Select frequency"
 *   inputType="frekvens"
 *   firstOptions={['Daily', 'Weekly']}
 *   secondOptions={['Hours', 'Minutes']}
 *   onValueChange={(value) => console.log(value)}
 * />
 */
const Input: React.FC<InputProps> = (props) => {
    const {
        inputText,
        inputLabel,
        inputType,
        options,
        placeholder,
        inputWidth,
        onValueChange,
        validationMessage
    } = props;
    
    // Variables for specific props - nødvendigt for at props vises afhængigt af inputType
    let pMin: number | undefined;
    let pMax: number | undefined;
    let firstOptions: FrekvensOptions[] | undefined;
    let secondOptions: FrekvensOptions[] | undefined;
    let textBefore: string | undefined;
    let textMid: string | undefined;
    let textAfter: string | undefined;
    let placeholderNumber: number | undefined;
    
    if (inputType === 'procent') {
        ({ pMin, pMax } = props as ProcentInputProps);
    }

    if (inputType === 'frekvens') {
        ({ firstOptions, secondOptions, textBefore, textMid, textAfter, placeholderNumber } = props as FrekvensInputProps);
    }

    if (inputType === 'number') {
        ({ placeholderNumber } = props as NumberInputProps);
    }
    
    const InputGUID = Math.random().toString(36).substring(7);
    const [inputValue, setInputValue] = useState('');
    
    //
    const [inputValueNum, setInputValueNum] = useState<string | number>(() => {
        if (inputType === 'number' || inputType === 'procent' || inputType === 'frekvens') {
            if (placeholderNumber !== undefined) {
                return placeholderNumber.toString(); // Gem som streng
            } else if (inputType === 'procent' && pMin !== undefined) {
                return pMin.toString();
            } else {
                return ''; // Tillad tom værdi som standard
            }
        } else {
            return '';
        }
    });
    const [inputSelectValue1, setInputSelectValue1] = useState(inputType === 'frekvens' && firstOptions ? firstOptions[0] : '');
    const [inputSelectValue2, setInputSelectValue2] = useState(inputType === 'frekvens' && secondOptions ? secondOptions[1] : '');
    const [calculatedNewValue, setCalculatedNewValue] = useState(1);
    const [calculatedSelectValue1, setCalculatedSelectValue1] = useState("");

    useEffect(() => {
        if (onValueChange) {
            if (inputType === 'text' || inputType === 'radio') {
                onValueChange(inputValue);
            } else if (inputType === 'number' || inputType === 'procent') {
                onValueChange(inputValueNum);
            } else if (inputType === 'select') {
                onValueChange(inputValue);
            }
        }
    });

    useEffect(() => {
        if (inputType === 'frekvens') {
            // Switch med case til hver option i FrekvensOptions
            let newValue: ProcessTid = {
                procesTidISekunder: 0,
                frekvens: inputSelectValue1,
                om: inputSelectValue2
            };

            switch (inputSelectValue2) {
                case "timer":
                case "timen":
                    newValue.procesTidISekunder = Number(inputValueNum) * 3600;// 1 time = 3600 sekunder = 60 minutter * 60 sekunder
                    break;
                case "minutter":
                case "minut":
                case "minuttet":
                    newValue.procesTidISekunder = Number(inputValueNum) * 60; // 1 minut = 60 sekunder
                    break;
                case "dagligt (kun hverdage)":
                case "dagligt (også weekender)":
                    newValue.procesTidISekunder = Number(inputValueNum) * 86400; // 1 dag = 86400 sekunder = 24 timer * 60 minutter * 60 sekunder
                    break;
                case "ugentligt":
                    newValue.procesTidISekunder = Number(inputValueNum) * 604800; // 1 uge = 604800 sekunder = 7 dage * 24 timer * 60 minutter * 60 sekunder
                    break;
                case "månedligt":
                    newValue.procesTidISekunder = Number(inputValueNum) * 2628000; // 1 måned = 2628000 sekunder = 30,44 dage * 24 timer * 60 minutter * 60 sekunder
                    break;
                case "årligt":
                    newValue.procesTidISekunder = Number(inputValueNum) * 31536000; // 1 år = 31536000 sekunder = 365 dage * 24 timer * 60 minutter * 60 sekunder
                    break;
                default:
                    newValue.procesTidISekunder = Number(inputValueNum); // Eller en anden standard handling
                    break;
                    
            }
            if (onValueChange && (newValue.procesTidISekunder !== calculatedNewValue || newValue.frekvens !== calculatedSelectValue1)) {
                setCalculatedNewValue(newValue.procesTidISekunder); //Sætter et sammenligningsfelt så infinit loop undgås
                setCalculatedSelectValue1(newValue.frekvens); //Sætter et sammenligningsfelt så infinit loop undgås
                onValueChange(newValue);
            }
        }
    }, [inputValueNum, inputSelectValue1, inputSelectValue2, onValueChange]);

    // Håndterer ændringer i input med type "text"
    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = e.target.value; // string
        setInputValue(newValue);
        if (onValueChange) {
            onValueChange(newValue); // sender string
        }
    };
    
    // Opdateret handler for tal
    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue === '' || !isNaN(Number(newValue))) {
            // Tillad tom værdi eller gyldigt tal
            setInputValueNum(newValue === '' ? '' : Number(newValue));
            if (onValueChange) {
                onValueChange(newValue === '' ? '' : Number(newValue));
            }
        }
    };
    
    // Håndterer ændringer i første input med type "select"
    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setInputSelectValue1(newValue);
    };

    // Håndterer ændringer i andet input med type "select"
    const handleSelectChange2 = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value;
        setInputSelectValue2(newValue);
    };

    // Håndterer ændringer i input med type "radio"
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onValueChange) {
            onValueChange(newValue); // Kald onValueChange med string værdi
        }
    };

    return (
        <Container>
            <Form.Group controlId={`Form.${inputType}`}>
                <Form.Label>
                    <strong>{inputLabel}</strong>
                    {validationMessage ? <><br /><Form.Text className="text-danger">{validationMessage}</Form.Text></> : null}
                </Form.Label>
                {inputType === 'select' ? (
                    <Form.Select aria-label="Default select example" value={inputValue} onChange={handleSelectChange}>
                        <option>Vælg</option>
                        {options && options.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                    </Form.Select>
                ) : inputType === 'radio' ? (
                    <div>
                        {options && options.map((option, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                label={option}
                                name="formHorizontalRadios"
                                id={`${InputGUID}formHorizontalRadios${index}`}
                                onChange={handleRadioChange}
                            />
                        ))}
                    </div>
                ): inputType === 'frekvens' ? (
                    <div className="d-flex flex-wrap">
                        <div className='d-flex flex-wrap wrap justify-content-start'>
                        {textBefore ? <p className="text-nowrap my-2 px-2 pb-1">{textBefore}</p> : null}
                        <Form.Select style={{width: "15em"}} aria-label="Default select example" value={inputSelectValue1 as FrekvensOptions} onChange={handleSelectChange}>
                            {firstOptions && firstOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                        {textMid ? <p className="text-nowrap my-2 px-2 pb-1">{textMid}</p> : null}
                        </div>
                        <div className='d-flex flex-wrap justify-content-start'>
                        <Form.Control style={{maxWidth: "6em"}} type="number" value={inputValueNum} onChange={handleNumberChange} />                      
                        <Form.Select style={{width: "8em"}} aria-label="Default select example" value={inputSelectValue2 as FrekvensOptions} onChange={handleSelectChange2}>
                            {secondOptions && secondOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </Form.Select>
                        {textAfter ? <p className="text-nowrap my-2 px-2 pb-1">{textAfter}</p> : null}
                        </div>
                    </div>

                ): inputType === 'time' ? (
                    <><p>Input time</p></>
                ): inputType === 'procent' && pMin !== undefined && pMax !== undefined ? (
                    <>
                        <div className='d-flex'>
                        <Form.Range 
                            min={pMin as number}
                            max={pMax as number} 
                            value={inputValueNum} 
                            onChange={handleNumberChange} 
                        />
                        <Form.Control 
                            type="number" 
                            value={inputValueNum}
                            onChange={handleNumberChange}
                            style={{ width: "4.2em", display: "inline-block", marginLeft: "10px" }} 
                        />
                    </div>
                    </>
                ): inputType === 'checkbox' ? (
                    <><><p>Input checkbox</p></></>
                ): inputType === "text" ? (
                    <Form.Control style={{width: '100%', maxWidth: `${inputWidth}em`}} type={inputType} placeholder={placeholder} value={inputValue} onChange={handleTextChange} />
                ): inputType === "number" ? (
                    <Form.Control style={{maxWidth: `${inputWidth}em`}} type={inputType} value={inputValueNum} onChange={handleNumberChange} />
                ): null
                
                }
                <Form.Text className="text-muted mx-1">{inputText}</Form.Text>
            </Form.Group>
        </Container>
    );
};

export default Input;