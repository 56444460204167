import { Tooltip, OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';

// Definer typen for props
interface QuestionIconWithTooltipProps {
  text: string;
  placement?: OverlayTriggerProps['placement']; // Brug 'placement' type fra OverlayTrigger
  className?: string; // Tilføj className som en valgfri prop
}

// Kommentar: QuestionIconWithTooltip bruges i flere komponenter til at vise en tooltip med hjælpende tekst ved hover over et spørgsmålstegn-ikon.
/**
 * QuestionIconWithTooltip komponentet viser et spørgsmålstegn-ikon, som når det hoveres over,
 * viser en tooltip med en hjælpetekst. Tooltippen kan placeres på forskellige positioner
 * omkring ikonet.
 *
 * @param {Object} props - Komponentens props.
 * @param {string} [props.text="Her er din hjælpende tekst!"] - Teksten der vises i tooltippen.
 * @param {string} [props.placement="right"] - Placeringen af tooltippen i forhold til ikonet.
 *                                             Mulige værdier inkluderer "top", "right", "bottom", og "left".
 * @param {string} [props.className] - Ekstra CSS-klasser til ikonet.
 *
 * @example
 * <QuestionIconWithTooltip text="Dette er en tooltip" placement="top" className="my-custom-class" />
 */
const QuestionIconWithTooltip: React.FC<QuestionIconWithTooltipProps> = ({
  text = "Her er din hjælpende tekst!",
  placement = "right",
  className, // Modtag className som en prop
}) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={placement} // Placering fra props
      delay={{ show: 250, hide: 400 }} // Forsinkelse på tooltip
      overlay={renderTooltip}
    >
      {({ ref, ...triggerHandler }) => (
        <div ref={ref} {...triggerHandler} style={{ display: 'inline-block' }}>
          <QuestionCircle
            size={20}
            style={{ cursor: 'pointer' }}
            className={className}
          /> {/* Spørgsmålstegn-ikon */}
        </div>
      )}
    </OverlayTrigger>
  );
};

export default QuestionIconWithTooltip;