import React from 'react';
import { useEffect, useRef } from 'react';

type ROIChartProps = {
    /** ROI efter $1 som en procentværdi */
    ROI3Mdr: number;
    /** ROI efter $1 som en procentværdi */
    ROI6Mdr: number;
    /** ROI efter 1 år som en procentværdi */
    ROI12Mdr: number;
    /** ROI efter 2 år som en procentværdi */
    ROI2Year: number;
    /** ROI efter 3 år som en procentværdi */
    ROI3Year: number;
    /** ROI efter 4 år som en procentværdi */
    ROI4Year: number;
    /** ROI efter 5 år som en procentværdi */
    ROI5Year: number;
    /** Højde på grafen angivet i em */
    heightEm: number;
};

const ROIChart: React.FC<ROIChartProps> = ({ ROI3Mdr, ROI6Mdr, ROI12Mdr, ROI2Year, ROI3Year, ROI4Year, ROI5Year, heightEm }) => {
    // Definer data til grafen baseret på de props, der gives
    const data = [
        { month: 3, ROI: ROI3Mdr },
        { month: 6, ROI: ROI6Mdr },
        { month: 12, ROI: ROI12Mdr },
        { month: 24, ROI: ROI2Year },
        { month: 36, ROI: ROI3Year },
        { month: 48, ROI: ROI4Year },
        { month: 60, ROI: ROI5Year },
    ];

    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                // Set canvas dimensions with scaling for high resolution
                const dpr = window.devicePixelRatio || 1;
                const logicalWidth = canvas.parentElement ? canvas.parentElement.clientWidth : 600;
                const logicalHeight = heightEm * 16; // Convert em to pixels (assuming 1em = 16px)
                canvas.width = logicalWidth * dpr;
                canvas.height = logicalHeight * dpr;
                canvas.style.width = '100%';
                canvas.style.height = `${heightEm}em`;
                ctx.scale(dpr, dpr);

                // Clear canvas before drawing
                ctx.clearRect(0, 0, logicalWidth, logicalHeight);

                // Set up chart dimensions
                const width = logicalWidth; // logical width for drawing
                const height = logicalHeight; // logical height for drawing
                const maxLabelLength = Math.max(...data.map((d) => d.ROI.toFixed(0).length));
                const padding = 20 + maxLabelLength * 1.5 * 16; // Dynamically adjust padding based on the length of the longest label
                const chartWidth = width - padding * 2;
                const chartHeight = height - padding * 2;

                // Determine Y-axis interval using a dynamic approach
                let yInterval = 10;
                const minValueRaw = Math.min(...data.map((d) => d.ROI));
                const maxValueRaw = Math.max(...data.map((d) => d.ROI));
                let minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                let maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;
                let numberOfPoints = (maxValue - minValue) / yInterval;

                let iteration = 0;
                while (numberOfPoints > 12) {
                    yInterval *= 2;
                    iteration++;
                    minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                    maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;
                    numberOfPoints = (maxValue - minValue) / yInterval;
                }

                // Recalculate min and max values based on new interval
                minValue = Math.floor(minValueRaw / yInterval) * yInterval;
                maxValue = Math.ceil(maxValueRaw / yInterval) * yInterval;

                // Draw axes
                ctx.beginPath();
                ctx.lineWidth = 4; // Gør akselinjerne tykkere
                ctx.moveTo(padding, padding);
                ctx.lineTo(padding, height - padding);
                ctx.lineTo(width - padding, height - padding);
                ctx.stroke();

                // Draw Y-axis label
                ctx.fillStyle = '#000';
                ctx.textAlign = 'center';
                ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                ctx.fillText('ROI (%)', padding - 50, padding / 2); // Position label above Y-axis

                // Draw horizontal red line at y=0 if 0 is within range
                if (minValue < 0 && maxValue > 0) {
                    const zeroY = height - padding - ((0 - minValue) / (maxValue - minValue)) * chartHeight;
                    ctx.beginPath();
                    ctx.moveTo(padding, zeroY);
                    ctx.lineTo(width - padding, zeroY);
                    ctx.strokeStyle = 'red';
                    ctx.lineWidth = 3; // Gør den røde linje tykkere
                    ctx.stroke();

                    // Draw label above red line
                    ctx.fillStyle = 'red';
                    ctx.textAlign = 'right';
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText('Investering og afkast har udlignet sig', width - padding, zeroY - 10);
                }

                // Draw line chart
                ctx.beginPath();
                ctx.lineWidth = 3; // Gør grafens linje tykkere
                data.forEach((point, index) => {
                    const x = padding + (point.month / 60) * chartWidth;
                    const y = height - padding - ((point.ROI - minValue) / (maxValue - minValue)) * chartHeight;
                    if (index === 0) {
                        ctx.moveTo(x, y);
                    } else {
                        ctx.lineTo(x, y);
                    }
                });
                ctx.strokeStyle = '#8884d8';
                ctx.stroke();

                // Draw data points
                ctx.fillStyle = '#8884d8';
                data.forEach((point) => {
                    const x = padding + (point.month / 60) * chartWidth;
                    const y = height - padding - ((point.ROI - minValue) / (maxValue - minValue)) * chartHeight;
                    ctx.beginPath();
                    ctx.arc(x, y, 5, 0, 2 * Math.PI);
                    ctx.fill();
                });

                // Draw X-axis labels (with intervals of 3 months from 0 to 60)
                ctx.fillStyle = '#000';
                ctx.textAlign = 'center';
                for (let i = 0; i <= 60; i += 3) {
                    const x = padding + (i / 60) * chartWidth;
                    const y = height - padding + 30;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText(`${i}`, x, y);
                }

                // Draw label under X-axis
                // Text size can be adjusted here with ctx.font in em to match the website's text size
                ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                ctx.fillText('Tid (Måneder)', width / 2, height - padding + 70);

                // Draw vertical lines for each year and label them
                ctx.fillStyle = '#888888';
                ctx.textAlign = 'center';
                ctx.font = `1em Arial`; // Set text size for year labels
                [12, 24, 36, 48, 60].forEach((month) => {
                    const x = padding + (month / 60) * chartWidth;
                    ctx.beginPath();
                    ctx.setLineDash([5, 5]); // Set line dash pattern for vertical line
                    ctx.moveTo(x, padding);
                    ctx.lineTo(x, height - padding);
                    ctx.strokeStyle = '#cccccc';
                    ctx.lineWidth = 1;
                    ctx.stroke();
                    ctx.setLineDash([]); // Reset line dash pattern
                    // Draw year label
                    ctx.fillText(`${month / 12} år`, x, padding - 10);
                });

                // Draw Y-axis labels in determined intervals with dotted lines, except for 0 and minValue
                ctx.textAlign = 'right';
                ctx.textBaseline = 'middle';
                for (let value = minValue; value <= maxValue; value += yInterval) {
                    const y = height - padding - ((value - minValue) / (maxValue - minValue)) * chartHeight;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                    if (value !== 0 && value !== minValue) {
                        ctx.fillText(value.toFixed(0) + '%', padding - 20, y);

                        // Draw horizontal dotted line
                        ctx.beginPath();
                        ctx.setLineDash([5, 5]); // Set line dash pattern for dotted line
                        ctx.moveTo(padding, y);
                        ctx.lineTo(width - padding, y);
                        ctx.strokeStyle = '#cccccc';
                        ctx.lineWidth = 1;
                        ctx.stroke();
                        ctx.setLineDash([]); // Reset line dash pattern
                    }
                }

                // Draw Y-axis label for 0 if within range
                if (minValue < 0 && maxValue > 0) {
                    const zeroY = height - padding - ((0 - minValue) / (maxValue - minValue)) * chartHeight;
                    // Text size can be adjusted here with ctx.font in em to match the website's text size
                    ctx.font = `1em Arial`; // Set text size and font in em based on heightEm
                    ctx.fillText('0%', padding - 20, zeroY);
                }
            }
        }
    }, [ROI3Mdr, ROI6Mdr, ROI12Mdr, ROI2Year, ROI3Year, ROI4Year, ROI5Year, heightEm]);

    return <canvas ref={canvasRef}></canvas>;
};

export default ROIChart;
