/**
 * Beregner penge tjent pr. dag baseret på timeløn og tid brugt pr. dag.
 * @param timeLoen - Timelønnen i kroner.
 * @param tidBrugtPrDag - Tid brugt pr. dag i timer.
 * @returns Penge tjent pr. dag.
 */
export function pengePrDag(timeLoen: number, tidBrugtPrDag: number): number {
    return timeLoen * tidBrugtPrDag;
}

/**
 * Beregner penge tjent pr. uge baseret på timeløn og tid brugt pr. uge.
 * @param timeLoen - Timelønnen i kroner.
 * @param tidBrugtPrUge - Tid brugt pr. uge i timer.
 * @returns Penge tjent pr. uge.
 */
export function pengePrUge(timeLoen: number, tidBrugtPrUge: number): number {
    return timeLoen * tidBrugtPrUge;
}

/**
 * Beregner penge tjent pr. måned baseret på timeløn og tid brugt pr. måned.
 * @param timeLoen - Timelønnen i kroner.
 * @param tidBrugtPrMåned - Tid brugt pr. måned i timer.
 * @returns Penge tjent pr. måned.
 */
export function pengePrMåned(timeLoen: number, tidBrugtPrMåned: number): number {
    return timeLoen * tidBrugtPrMåned;
}

/**
 * Beregner penge tjent pr. år baseret på timeløn og tid brugt pr. år.
 * @param timeLoen - Timelønnen i kroner.
 * @param tidBrugtPrÅr - Tid brugt pr. år i timer.
 * @returns Penge tjent pr. år.
 */
export function pengePrÅr(timeLoen: number, tidBrugtPrÅr: number): number {
    return timeLoen * tidBrugtPrÅr;
}