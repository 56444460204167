
import React from 'react';

interface BarometerProps {
    label?: string;
    value: number; // Value from 0 to 100
}

/**
 * Barometer component displays a semi-circular gauge with a needle indicating a value between 0 and 100.
 * It uses an SVG path to create the gauge and CSS for styling the needle and other elements.
 *
 * @component
 * @example
 * ```tsx
 * <Speedometer value={75} label="Speed" />
 * ```
 *
 * @param {SpeedometerProps} props - The properties for the Speedometer component.
 * @param {string} [props.label] - Optional label to display above the speedometer.
 * @param {number} props.value - The value to display on the speedometer, ranging from 0 to 100.
 *
 * @returns {JSX.Element} A React component that renders a speedometer gauge.
 */
const Barometer: React.FC<BarometerProps> = ({ value, label}) => {
    const result = value / 100;
    const needleStyle = {
        // Rotate the needle based on the value but if result is 100, then set it to 1 to avoid a full rotation
        // If result is 0 or less, set it to 0 to avoid negative rotation
        transform: `rotate(${(result > 1 ? 1 : result < 0 ? 0 : result) * 180 - 90}deg)`,
    };

    return (
        <>
            {label && <label style={{ display: 'block', textAlign: 'center', marginBottom: '0.3em', marginTop: '0.5em' }}>{label}</label>}
            <div style={styles.speedometer}>
                <svg viewBox="0 0 200 100" style={styles.speedometerSvg}>
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="100%" x2="100%" y2="100%">
                            <stop offset="0%" style={{ stopColor: 'green', stopOpacity: 1 }} />
                            <stop offset="50%" style={{ stopColor: 'yellow', stopOpacity: 1 }} />
                            <stop offset="100%" style={{ stopColor: 'red', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M 10 90 A 80 80 0 0 1 190 90"
                        stroke="url(#grad1)"
                        strokeWidth="20" // Increased strokeWidth
                        strokeLinecap="round" // Make the ends of the stroke rounded
                        fill="none"
                    />
                </svg>
                <div style={styles.speedometerInner}>
                    <div style={{ ...styles.speedometerNeedle, ...needleStyle }}>
                        <div style={styles.speedometerNeedleInner}></div>
                    </div>
                    <div style={styles.speedometerNeedleCircle}></div>
                    <div style={styles.innerWhiteCircle}></div>
                    <div style={styles.speedometerGraduation}></div>
                </div>
            </div>
        </>
    );
};

const styles = {
    speedometer: {
        position: 'relative' as 'relative',
        width: '100%',
        paddingBottom: '60%',
        overflow: 'hidden' as 'hidden',
    },
    speedometerSvg: {
        position: 'absolute' as 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    speedometerInner: {
        position: 'absolute' as 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
    },
    speedometerNeedle: {
        position: 'absolute' as 'absolute',
        bottom: '15%',
        left: '48%',
        width: '4%',
        height: '75%',
        backgroundColor: 'black',
        transformOrigin: 'bottom',
        transition: 'transform 0.5s ease-out',
        clipPath: 'polygon(50% 0%, 100% 100%, 0% 100%)', // Adding a pointed tip
        zIndex: 1,
    },
    speedometerNeedleInner: {
        position: 'absolute' as 'absolute',
        bottom: '0',
        left: '50%',
        width: '25%',
        height: '60%',
        backgroundColor: 'var(--other-color2)',
        transform: 'translateX(-50%)',
        clipPath: 'polygon(50% 0%, 100% 100%, 0% 100%)', // Adding a pointed tip
    },
    speedometerNeedleCircle: {
        position: 'absolute' as 'absolute',
        bottom: '15%',
        left: '50%',
        width: '8%',
        height: 'calc(8% * 1.4)',
        backgroundColor: 'black',
        borderRadius: '50%',
        transform: 'translateX(-50%) translateY(50%)',
        zIndex: 0,
    },
    innerWhiteCircle: {
        position: 'absolute' as 'absolute',
        bottom: '15%',
        left: '50%',
        width: '3%',
        height: 'calc(3% * 1.4)',
        backgroundColor: 'var(--other-color2)',
        borderRadius: '50%',
        transform: 'translateX(-50%) translateY(50%)',
        zIndex: 2,
    },
    speedometerGraduation: {
        position: 'absolute' as 'absolute',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '0.3em',
        backgroundColor: 'black',
    },
};

export default Barometer;