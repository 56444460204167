import './App.css';
import ForsidePage from './pages/ForsidePage';
import { BrowserRouter, Outlet } from "react-router-dom";
import RaportGenereator from './pages/RapportGenerator';
import NavbarMain from './components/navbarMain';
import RouteMap from './components/routeMap';
import FooterMain from './components/footerMain';
import OmRapportGeneratorPage from './pages/OmRapport-generatorenPage';
import { DropdownItem } from './models/dropdownModel';
import UnderKonstruktionPage from './pages/under_konstruktionPage';

// Navbar links sat op i en funktion for at undgå skulle lave om på path i flere filer
function navLinks(): { label: string; path: string; dropdown?: DropdownItem[]; page?: React.ReactNode }[] {
  return [
    { label: 'Forside', path: '/', page: <ForsidePage /> },
    { label: 'Værktøjer', path: '' , dropdown: [ 
      { label: 'Sådan bruger du værktøjerne', path: '/how-to', page: <OmRapportGeneratorPage /> },
      //{ label: 'Sådan bruger du værktøjerne (Under konstruktion)', path: '/how-to', page: <UnderKonstruktionPage /> },
      { divider: true },
      { label: 'ROI-rapport', path: '/rapportgenerator', page: <RaportGenereator /> }
      ]  
    },
    // { label: 'Rapport-generator', path: '/rapportgenerator', page: <RaportGenereator /> }
  ];
};


function BasisLayout() {
  return (
    <>
      <NavbarMain 
        brandName='Weng Consult'
        imgPath='/logoRundVS.png'
        links={navLinks()}
      />
      <div >
      <Outlet />
      </div>
      <FooterMain />
    </>
  );
}

function App() {
  return (
    <>
      <BrowserRouter>
            <RouteMap links={navLinks()} basicLayout={<BasisLayout/>} />
      </BrowserRouter> 
    </>
  );
}

export default App;
