
import React, { ReactNode } from "react";
import { Container, Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import NavbarLink from "./navbarLinks";
import { DropdownItem } from '../models/dropdownModel';

interface NavbarMainProps {
    brandName: string;
    imgPath?: string;
    links: { label: string; path: string; dropdown?: DropdownItem[]; page?: React.ReactNode }[];
}


/**
 * NavbarMain component renders a responsive navigation bar using React Bootstrap.
 * 
 * @component
 * @param {NavbarMainProps} props - The properties object.
 * @param {string} props.brandName - The name of the brand to be displayed on the navbar.
 * @param {string} [props.imgPath] - Optional path to the brand logo image.
 * @param {Array<{ label: string, path: string, dropdown?: { label: string; path: string; page: ReactNode; }[], page?: ReactNode}>} props.links - An array of link objects to be displayed in the navbar. Each link can optionally contain a dropdown menu with additional links.
 * 
 * @example
 * // Example usage of NavbarMain component
 * const links = [
 *   { label: "Home", path: "/" },
 *   { label: "About", path: "/about" },
 *   { label: "Services", path: "/services", dropdown: [
 *     { label: "Web Development", path: "/services/web-dev", page: <WebDevPage /> },
 *     { label: "SEO", path: "/services/seo", page: <SEOPage /> }
 *   ]}
 * ];
 * 
 * <NavbarMain brandName="MyBrand" imgPath="/path/to/logo.png" links={links} />
 */
const NavbarMain: React.FC<NavbarMainProps> = ({ brandName, imgPath, links }) => {
    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand href="/">
                    {imgPath && (
                        <img
                            src={imgPath}
                            width="30"
                            height="30"
                            className="d-inline-block align-top me-1"
                            alt="Brand logo"
                        />
                    )}
                    {brandName}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {links.map((link, index) => (
                            <NavbarLink key={index} label={link.label} path={link.path} dropdown={link.dropdown} page={link.page} />
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarMain;