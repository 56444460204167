import AppFrame from './AppFrame';
import Hero from '../components/hero';
import VideoSection from '../components/video-section';
import ImageSection from '../components/image-section';
import { Link } from 'react-router-dom';

const OmRapportGeneratorPage: React.FC = () => {
    return (
        <>
           <AppFrame children={
                <>
                    {/* <VideoSection 
                        title="Kan det betale sig?" 
                        text={<>
                        <p><b>- Er du i tvivl om hvorvidt det kan betale sig at automatisere en proces?</b></p>
                        <p>Kunne du tænke dig at få hurtig afklaring inden for få minutter?</p>
                        <p>Se videoen og få et hurtigt indblik i hvordan, Weng Consult process-rapport værktøj kan give dig et overblik på få minutter.</p>
                        </>} 
                        videoLink="/video/Intro.mp4"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={true}
                    /> */}
                    {/* <ImageSection 
                        title="Første skridt mod effektivisering er kortlæggning af processerne" 
                        text={<>
                        <p>Hvis du gerne vil have en effektiv forretning, er det alpha og omega at har styr på din organsiations værdikæde!</p>
                        <p>Vi hjælper dig med at kortlægge dine processer, rafinerer dem, og skabe overblik over de krav netop din organisation har til software, IT-system eller RPA-løsning.</p>
                        <p className='px-5'>
                            <em>Har i styr på jeres nuværende arbejdsgange, men planlægger at omlægge til automaiske processer?</em><br/>
                            <Link to={"/"} >Sådan planlægger i automatisering med succes.</Link>
                        </p>
                        <p>På den måde ved i nøjagtig hvad i har brug for, og kan undgå at investere i unødvendige løsninger, eller blive forført af udbydere som ikke kan levere det i har brug for.</p>
                        <p>Vi hjælper uanset om i ingen erfaring har med automation, eller om i allerede har eksisterende systemer i vil optimerer.</p>
                        </>} 
                        imageLink="/Process-map1.webp"
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={false}
                    />    */}
                    <ImageSection 
                        title="Hvordan fungerer det?" 
                        text={<>
                            <p>
                                <em>Besvar 3-5 lette spørgsmål og få en rapport på 2 min.</em>
                            </p>
                            <p>
                                Det er nemt at komme igang. 
                                <br/>
                                Du skal blot indtaste oplysninger om 3 nøgleparametre, og så vil vores værktøj generere en rapport for dig.
                            </p>
                            <p>
                                Det skal du have klar:
                            </p>
                            <p>
                                - Tid der bruges på opgaven (pr. dag, uge eller måned)<br/>
                                - Antal medarbejdere som beskæftiger sig med opgaven<br/>
                                - Medarbejderens gennemsnitlige timeløn<br/>
                                - Din målsætning (Hvor stor en del af processen ønsker du at overlade til en robot)<br/>
                                - <em>(valgfrit)</em> Oplys et tilbud fra en leverandør - for at se din ROI over tid. (Return on investment)
                            </p>
                            <p>
                                Det er alt du skal bruge for at få en rapport der viser dig om det kan betale sig at automatisere.
                            </p>
                            <p>

                            </p>
                        </>} 
                        imageLink="/chaos-to-order1.webp"
                        maxParagraphsOnImageSide={5}
                        primText="Kontakt os"
                        secText="Læs mere"
                        className='mt-5'
                        bg='var(--70color)'
                        firstPath='/kontakt'
                        secondPath='/om-os'
                        reverseLayout={true}
                    />   
                </>
           } /> 
        </>
    );
}

export default OmRapportGeneratorPage;