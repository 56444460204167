export function sekunderDagligt(frekvens: string, sekunder: number ): number {
    switch (frekvens) {
        case "time":
        case "timer":
        case "timen":
            return sekunder / 24;
        case 'dagligt (kun hverdage)':
        case 'dagligt (også weekender)':
            return sekunder;
        case 'ugentligt':
            return sekunder / 7;
        case 'månedligt':
            return sekunder / (365/12);
        default:
            throw new Error("Frekvens ikke genkendt");
    }
}

export function tidTilUger(frekvens: string, sekunder: number): number {
    switch (frekvens) {
        case "time":
        case "timer":
        case "timen":
            return sekunder * 7 * 24; // 7 dage om ugen, 24 timer om dagen
        case 'dagligt (kun hverdage)':
            return sekunder * 5;
        case 'dagligt (også weekender)':
            return sekunder * 7;
        case 'ugentligt':
            return sekunder;
        case 'månedligt':
            return sekunder / 4.33;
        default:
            throw new Error("Frekvens ikke genkendt");
    }
}

export function tidTilMåneder(frekvens: string, sekunder: number): number {
    switch (frekvens) {
        case "time":
        case "timer":
        case "timen":
            return (sekunder * 365 * 24) / 12;
        case 'dagligt (kun hverdage)':
            return sekunder * ((5*52) / 12);
        case 'dagligt (også weekender)':
            return sekunder * (365 / 12);
        case 'ugentligt':
            return sekunder * 4.33;
        case 'månedligt':
            return sekunder;
        default:
            throw new Error("Frekvens ikke genkendt");
    }
}

export function tidTilÅr(frekvens: string, sekunder: number): number {
    switch (frekvens) {
        case "time":
        case "timer":
        case "timen":
            return sekunder * 365 * 24;
        case 'dagligt (kun hverdage)':
            return sekunder * (5*52);
        case 'dagligt (også weekender)':
            return sekunder * 365;
        case 'ugentligt':
            return sekunder * 52;
        case 'månedligt':
            return sekunder * 12;
        default:
            throw new Error("Frekvens ikke genkendt");
    }
}